<template>
  <base-tile :title="$t('users.info')" i18nPrefix="users" :rows="state.rows">
    <!-- ID -->
    <template #[state.readUserIdSlot]>
      <td v-if="state.id" class="p-2">
        <span>{{ state.id }} </span>
        <button-clipboard-copy :field="$t('users.id')" :value="state.id" />
      </td>
    </template>

    <!-- EMAIL -->
    <template #[state.readUserEmailSlot]>
      <td v-if="state.email" class="p-2">
        <span>{{ state.email }} </span>
        <button-clipboard-copy
          :field="$t('users.email')"
          :value="state.email"
        />
      </td>
    </template>
  </base-tile>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import BaseTile from "@/components/BaseTile.vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { printDate } from "@/utils";

const { t } = useI18n();
const store = useStore();

const state = reactive({
  id: store.state.identity.user.id,
  email: store.state.identity.user.email,
  displayName: store.state.identity.user.displayName,
  createdAt: store.state.identity.user.createdAt,
  rows: computed(() => {
    const user = store.state.identity.user;
    return [
      {
        label: t("users.id"),
        value: user.id
      },
      {
        label: t("users.email"),
        value: user.email
      },
      {
        label: t("users.name"),
        value: user.displayName
      },
      {
        label: t("users.createdAt"),
        value: printDate(user.createdAt)
      }
    ];
  }),
  readUserIdSlot: `${t("users.id")}-value`,
  readUserEmailSlot: `${t("users.email")}-value`
});
</script>
