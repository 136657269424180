<template>
  <loading-spinner v-if="state.loading" />
  <div v-else>
    <the-header-card @refresh="loadUserInfo">
    </the-header-card>
    <div class="row row-equal">
      <div class="flex xs12 xl7">
        <user-information-tile />
      </div>
      <div class="flex xs12 xl5">
        <user-profile-tile />
      </div>

    </div>
    
    <div class="row row-equal">
      <div class="flex xs12 xl12">
        <user-auth-tile />
      </div>
   

    </div>
    
    <div class="row row-equal">
      <div class="flex xs12">
      </div>
    </div>

    <va-button-toggle
      outline
      class="mb-3"
      v-model="state.currentResourceSwitch"
      :options="SWITCH_OPTIONS"
    />
    <user-organizations-table
      v-show="state.currentResourceSwitch === 'organizations'"
      :params="{ userId: state.user.id }"
    />
    <entitlements-table
      v-show="state.currentResourceSwitch === 'entitlements'"
      :params="{ userId: state.user.id }"
    />
  </div>
  <danger-confirm-modal
    :is-open="state.showDangerModal"
    :title="$t('accounts.deletion')"
    :message="$t('accounts.messageModal')"
    :button-validate-message="$t('accounts.deletion')"
    @cancel="cancelDelete"
    @validate="deleteUser"
  />
</template>

<script setup>
import { inject, reactive, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TheHeaderCard from "@/components/TheHeaderCard.vue";
import UserInformationTile from "@/components/users/UserInformationTile.vue";
import UserProfileTile from "@/components/users/UserProfileTile.vue";
import UserAuthTile from "@/components/users/UserAuthTile.vue";
import EntitlementsTable from "@/components/entitlements/EntitlementsTable.vue";
import UserOrganizationsTable from "@/components/organizations/UserOrganizationsTable.vue";
import DangerConfirmModal from "@/components/modal/DangerConfirmModal.vue";
import { callDeleteUser } from "@/api/identity";

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();
const handleError = inject("handleError");

const SWITCH_OPTIONS = [
  { label: t("organizations.title"), value: "organizations" },
  { label: t("entitlements.title"), value: "entitlements" }
];

const state = reactive({
  loading: false,
  user: computed(() => store.state.identity.user),
  showDangerModal: false,
  currentResourceSwitch: "organizations",
});

const loadUserInfo = async () => {
  state.loading = true;

  // get user data
  const userId = route.params.id;
  await store.dispatch("identity/fetchUser", userId);

  state.loading = false;
};



const cancelDelete = () => {
  state.showDangerModal = false;
};

const deleteUser = async () => {
  const id = state.user.id;
  if (id) {
    try {
      await callDeleteUser(id);

      // remove it from the state
      store.state.identity.user = null;
      router.push({ path: "/admin/identity/users" });
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        handleError(err?.response?.data || err);
      }
    }
    state.showDangerModal = false;
  }
};
onMounted(async () => {
  await loadUserInfo();
});
</script>
