<template>
  <base-tile :title="$t('users.profile')" i18nPrefix="users" :rows="state.rows">
    <template #[state.readCountrySlot]>
      <td class="p-2">
        {{ state.country ? regionNames.of(state.country) : "/" }}
      </td>
    </template>
  </base-tile>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import BaseTile from "@/components/BaseTile.vue";

const { t } = useI18n();
const store = useStore();
const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

const state = reactive({
  givenName: store.state.identity.user.givenName,
  familyName: store.state.identity.user.familyName,
  locale: store.state.identity.user.locale,
  country: store.state.identity.user.country,
  phoneNumber: store.state.identity.user.phoneNumber,
  rows: computed(() => {
    const user = store.state.identity.user;
    return [
      {
        label: t("users.firstName"),
        value: user.givenName
      },
      {
        label: t("users.lastName"),
        value: user.familyName
      },
      {
        label: t("users.locale"),
        value: user.locale
          ? t(`general.languages.${user.locale.substring(0, 2)}`)
          : "/"
      },
      {
        label: t("users.country"),
        value: user.country
      },
      // {
      //   label: t("users.phoneNumber"),
      //   value: user.phoneNumber
      // }
    ];
  }),
  readCountrySlot: `${t("users.country")}-value`
});
</script>
