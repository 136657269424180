<template>
  <base-tile :title="$t('users.auth.title')" i18nPrefix="users" :rows="state.rows" :noDataMessage="$t('users.auth.noAuthMethods')">
    <template v-for="(row, index) in state.rows" :key="index" #[`${row.label}-value`]>
      <td class="p-2">
      
        <va-button
          v-if="row.deletable"
          color="#DC2626"
          flat
          icon="delete"
          @click="openDangerModal(row.value)" 
        />
      </td>
    </template>
  </base-tile>
  <danger-confirm-modal
    :is-open="state.showDangerModal"
    :title="$t('users.auth.deletion')"
    :message="$t('users.auth.messageModal')"
    :button-validate-message="$t('users.auth.deletion')"
    @cancel="cancelDelete"
    @validate="UnassignUserAuth"
  />
</template>

<script setup>
import { computed, reactive, onMounted, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import BaseTile from '@/components/BaseTile.vue';
import DangerConfirmModal from "@/components/modal/DangerConfirmModal.vue";
import { callFetchUserAuthMethods, callDeleteUserAuth } from '@/api/identity';

const toasts = inject("toasts");
const { t } = useI18n();
const store = useStore();

const state = reactive({
  authMethods: [],
  userId: store.state.identity.user.id,
  showDangerModal: false,
  deletableAuthID: null,
  rows: computed(() => {
    return state.authMethods.map(authMethod => ({
      label: t(`users.auth.${authMethod.type.toLowerCase()}`),
      value: authMethod.id,
      deletable: authMethod.deletable
    }));
  }),
});
const openDangerModal = (id) => {
  state.deletableAuthID = id
  state.showDangerModal = true;
};
const cancelDelete = () => {
  state.showDangerModal = false;
};
const fetchUserAuthMethods = async () => {
  try {
    const response = await callFetchUserAuthMethods(state.userId);
    state.authMethods = response.data.items;
  } catch (error) {
    console.error('Error fetching user auth methods:', error);
  }
};

// Unassign user authentication method
const UnassignUserAuth = async () => {
  try {
    const authMethodId = state.deletableAuthID ;
    await callDeleteUserAuth(state.userId, authMethodId);
    // Remove the auth method from the state after successful deletion
    
    state.authMethods = state.authMethods.filter(method => method.id !== authMethodId);
    state.showDangerModal = false;
    toasts.info(t(`users.auth.authDeleteingSucess`));
  } catch (error) {
    state.showDangerModal = true;
    console.error('Error deleting user auth method:', error);
  }
};

onMounted(fetchUserAuthMethods);
</script>

<style scoped>
.auth-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.no-auth-methods {
  padding: 1rem;
  font-weight: bold;
  color: #dc2626;
}
</style>
